import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import image from "../images/mollie_mcdowell_2019.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="MOLLIE MCDOWELL" />
    <div className="w-screen h-screen flex flex-col-reverse lg:flex-row fixed bg-canvas flex-reverse">
      <div className="h-half flex items-center p-6 lg:p-12 lg:w-1/2 lg:h-auto">
        <div className="flex flex-col">
          <h1 className="font-bold text-5xl lg:text-7xl uppercase font-display leading-tight">Mollie McDowell</h1>
          <h2 className="my-4 lg:my-8 tracking-widest">Ceramist • Designer • Child of the West</h2>
          <a className="text-sm" href="mailto:hi@molliemcdowell.com">hi@molliemcdowell.com</a>
        </div>
      </div>
      <div className="h-half lg:h-auto lg:w-1/2 bg-black bg-cover bg-center" style={{backgroundImage: `url(${image})`}}></div>
    </div>
  </Layout>
)

export default IndexPage
